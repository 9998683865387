<template>
  <td
    :class="`element-content cell_type_${cell.type}`"
    :data-clone-index="clone"
    :style="elementStyle"
  >
    <v-autocomplete
      v-model="value"
      :class="`dropdown-singleselect${classes}`"
      :disabled="disabled"
      :items="options"
      item-text="text"
      item-value="id"
      return-object
      autocomplete="on"
      hide-details
      :no-data-text="lang('ChecklistBuilder.templates.dropdown.option_no_data')"
      chips
      ligth="true"
    >
      <template
        slot="selection"
        slot-scope="data"
      >
        {{ data.item.text }}
      </template>
      <template
        slot="item"
        slot-scope="data"
      >
        <v-list-item-content>
          <v-list-item-title>
            {{ data.item.text }}
          </v-list-item-title>
        </v-list-item-content>
      </template>
    </v-autocomplete>
  </td>
</template>

<script>
export default {
  name: 'ChecklistCellTypeDropdownSql',
  props: [
    'x',
    'y',
    'cell',
    'checklist',
    'state',
    'clone',
    'dataViewer',
    'data'
  ],
  data() {
    return {
      options: []
    }
  },
  computed: {
    elementStyle() {
      let style = false
      const color = this.dataViewer.temporary_background_color ||
        this.dataViewer.background_color ||
        this.cell.settings.cell_background_color
      if (color) {
        style = `background-color: ${color};`
      }
      return style
    },
    classes() {
      return this.state.classes
    },
    disabled() {
      return this.state.disabled
    },
    reloadOptions() {
      return this.dataViewer.reloadOptions
    },
    value: {
      get() {
        const builder = this.data.ChecklistBuilder
        const index = this.checklist.index
        let value = builder.getCellValue(index, this.cell, this.clone)
        try {
          if (typeof value === 'string') {
            value = JSON.parse(value)
          }
        } catch (ex) {
          value = []
        }
        value = parseInt(builder.getSelectPropertyToArray(value, 'id')[0])
        return {
          id: value || 0
        }
      },
      set(event) {
        const builder = this.data.ChecklistBuilder
        const index = this.checklist.index
        let value = []
        if (event.hasOwnProperty('id')) {
          value = [{
            id: event.id,
            label: event.text || ''
          }]
        }
        const previousValue = this.value
        builder.evalValues({
          index: index,
          dataEditor: this.cell,
          dataViewer: this.dataViewer,
          cloneIndex: this.clone,
          encode: false,
          saveCallback: undefined,
          ignoreAutoSaveSetting: false,
          value: value,
          previousValue: previousValue
        })
      }
    }
  },
  watch: {
    question: (newValue, oldValue) => {
      if (newValue) {
        this.getOptions()
      }
      this.dataViewer.reloadOptions = false
    }
  },
  mounted() {
    this.getOptions()
  },
  methods: {
    lang(langKey) {
      const lang = this.data.ChecklistBuilder.getSetting('TRANSLATION')
      return lang(langKey)
    },
    getOptions() {
      const builder = this.data.ChecklistBuilder
      builder.getSqlData(this.cell).then((data) => {
        this.options = data || []
      })
    }
  }
}
</script>
